<template>
  <OForm :class="{ submitting }" @submit="submit">
    <OFormInput
      id="email"
      name="email"
      type="email"
      v-model="form.email"
      required
      placeholder="Enter your email"
    >
      E-mail on your account:
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Send new password
      </OFormSubmitButton>
    </FormButtons>
    <OFormHelp>
      A temporary password will be sent to your email.You are adviced to change it immediately after
      Log In. This would work, only if you have previously verified your email.
    </OFormHelp>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormInput from '../objects/forms/OFormInput'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormHelp from '../objects/forms/OFormHelp'

export default {
  name: 'CFormForgotPassword',
  components: { OFormHelp, OFormError, FormButtons, OFormSubmitButton, OFormInput, OForm },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        email: ''
      }
    }
  },
  methods: {
    submit () {
      this.$callApi(api => api.forgot(this.form))
    }
  }
}
</script>
