<template>
  <l-default>
    <OTitle>Reset password</OTitle>
    <c-form-forgot-password />
  </l-default>
</template>

<script>
import CFormForgotPassword from '../../forms/CFormForgotPassword'
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'

export default {
  name: 'PageForgotPassword',
  components: { OTitle, LDefault, CFormForgotPassword },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Forgot Password',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Forgot Password'
      },
      {
        name: 'keywords',
        content: 'myphonerobot forgot password'
      }
    ]
  }
}
</script>
